import React from "react";
import backgroundImage from './background.jpg';
import GAME from "./game";
import MotionAnimate from "../../components/MotionAnimate";
import Loader from "../../components/Loader";

class MartialArts extends React.Component {
    
    componentDidMount=()=>{
        GAME();
        const canvasElement = document.querySelector('canvas');
        canvasElement.style.backgroundImage = `url(${backgroundImage})`;
        canvasElement.style.backgroundSize = 'cover';
        canvasElement.style.backgroundPosition = 'center';
        canvasElement.style.height = '90%';
        canvasElement.style.width = '90%';
    };

    abilities = [
        {
            action: "kick",
            key: "[A]"
        },
        {
            action: "punch",
            key: "[D]"
        },
        {
            action: "backward",
            key: "[S]"
        },
        {
            action: "forward",
            key: "[W]"
        },
        {
            action: "block",
            key: "[Spacebar]"
        },
    ];
    render() {
    return <MotionAnimate><Loader /><div className="container">
            <h1 className="my-1 font-extrabold text-s font-sans drop-shadow-xl shadow-white text-center">Martial Arts Sim</h1>
        <div className="flex flex-col lg:flex-row lg:flex-shrink-0 items-center space-x-2 right-0">
                <canvas height={500} width={1000} className="m-1 rounded w-max-20"></canvas>
            <div className="w-full mt-2 justify-between">
                <h3 className="my-2">Play with buttons or key bindings</h3>
                {this.abilities.map((item) => {
                    return <button key={item.action} className="border font-bold px-3 py-1 mx-1 my-1 text-xs rounded hover:bg-white hover:text-black" id={item.action}>{item.action.toUpperCase()}<br /><span className="font-extralight">{item.key}</span></button>
                })}
                <div className="whitespace-pre-line bg-white"></div>
        <p className="text-gray-400 font-extralight mt-2">This sim is powered by HTML Canvas and Javascript. Capstone project for <a className="hover:text-gray-300" href="https://www.pupilfirst.school/courses/1087/curriculum"> Web Dev 101 on pupilfirst.school</a>.</p>
            </div>
        </div>
    </div></MotionAnimate>;
    };
}

export default MartialArts;