const loadImage = (frame, animation, callbackFunction) => {
    let img = document.createElement("img");
    img.onload = () => {
        callbackFunction(img);
    };
    img.src = `/images/${animation}/${frame}.png`;
}

const frames = {
    idle: [1, 2, 3, 4, 5, 6, 7, 8],
    kick: [1, 2, 3, 4, 5, 6, 7],
    punch: [1, 2, 3, 4, 5, 6, 7],
    backward: [1, 2, 3, 4, 5, 6],
    block: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    forward: [1, 2, 3, 4, 5, 6],
};

const loadImages = (callbackFunction) => {
    let images = {
        idle: [],
        kick: [],
        punch: [],
        backward: [],
        block: [],
        forward: []
    };
    let imagesToLoad = 0;

    ["idle", "kick", "punch", "backward", "block", "forward"].forEach(animation => {
        let animationFrames = frames[animation];
        imagesToLoad += animationFrames.length;

        animationFrames.forEach((frameNumber) => {
            loadImage(frameNumber.toString(), animation, (image) => {
                images[animation].push(image);
                imagesToLoad--;

                if (imagesToLoad === 0) {
                    callbackFunction(images);
                }
            });
        });
    });
};

const animate = (ctx, images, animation, callbackFunction) => {
    images[animation].forEach((image, index) => {
        setTimeout(() => {
            ctx.clearRect(0, 0, 500, 500);
            ctx.drawImage(image, 0, 0, 500, 500);
        }, index * 100);
    });
    setTimeout(callbackFunction, images[animation].length * 100);
};

const GAME = () => {
    const canv = document.querySelector('canvas');
    const ctx = canv.getContext("2d");

    loadImages((images) => {
        document.getElementById('loader').style.display = 'none';
        let queuedAnimations = [];
        const infiniteCall = () => {
            let selectedAnimation;
            selectedAnimation = queuedAnimations.length === 0 ? "idle" : queuedAnimations.shift();
            animate(ctx, images, selectedAnimation, () => document.location.href.includes("/martial-arts") ? infiniteCall() : null);
            const execute = (action) => {
                queuedAnimations.push(action);
            };
            document.getElementById("kick").onclick = () => execute("kick");
            document.getElementById("punch").onclick = () => execute("punch");
            document.getElementById("backward").onclick = () => execute("backward");
            document.getElementById("block").onclick = () => execute("block");
            document.getElementById("forward").onclick = () => execute("forward");
            document.onkeyup = (e) => {
                switch (e.key) {
                    case "a":
                        execute("kick");
                        break;
                    case "d":
                        execute("punch");
                        break;
                    case "s":
                        execute("backward");
                        break;
                    case "w":
                        execute("forward");
                        break;
                    case " ":
                        execute("block");
                        break;
                    default:
                        break;
                }
            }
        }
        infiniteCall();
    });

}

export default GAME;