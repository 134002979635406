import { FaGithub, FaLinkedinIn, FaMailBulk, FaTelegram } from 'react-icons/fa';
import MotionAnimate from '../components/MotionAnimate';

const Contact = () => {
    return <MotionAnimate><div className="container mx-auto">
        <h1 className="text-4xl font-extrabold text-center my-5 mb-10 italic">Say Hello 👋</h1>
        <div className="flex justify-center space-x-10 border rounded-xl md:mx-[10rem] py-5">
            <div className="flex flex-col lg:flex-auto items-center space-y-5">
                <div onClick={() => {window.open("https://telegram.me/rabilrbl", "_blank")}} className='cursor-pointer flex items-center justify-center hover:text-stone-400 mr-2 mb-2 drop-shadow-xl shadow-white delay-100 hover:shadow-stone-800 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-200'>
                    <FaTelegram className="text-6xl m-2 " />
                    <h3 className='text-2xl font-extralight'>DM on Telegram</h3>
                </div>
                <div onClick={() => {window.open("https://linkedin.com/in/rabilrbl", "_blank")}} className='cursor-pointer flex items-center justify-center hover:text-stone-400 mr-2 mb-2 drop-shadow-xl shadow-white delay-100 hover:shadow-stone-800 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-200'>
                    <FaLinkedinIn className="text-6xl m-2 " />
                    <h3 className='text-2xl font-extralight'>Connect on LinkedIn</h3>
                </div>
                <div onClick={() => {window.location.href ="mailto:rabil@email.com"}} className='cursor-pointer flex items-center justify-center hover:text-stone-400 mr-2 mb-2 drop-shadow-xl shadow-white delay-100 hover:shadow-stone-800 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-200'>
                    <FaMailBulk className="text-6xl m-2 " />
                    <h3 className='text-2xl font-extralight'>Send me an email</h3>
                </div>
                <div onClick={() => {window.open("https://github.com/rabilrbl", "_blank")}} className='cursor-pointer flex items-center justify-center hover:text-stone-400 mr-2 mb-2 drop-shadow-xl shadow-white delay-100 hover:shadow-stone-800 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-200'>
                    <FaGithub className="text-6xl m-2" />
                    <h3 className='text-2xl font-extralight'>Fork on GitHub</h3>
                </div>
            </div>
        </div>
    </div></MotionAnimate>
};

export default Contact;