// import { SiReact, SiTailwindcss } from "react-icons/si";
import MotionAnimate from "../components/MotionAnimate";

const About = () => {
    return <MotionAnimate >
        <h1 className="text-2xl text-gray-300 text-center mb-5 lg:text-6xl font-extrabold">About me</h1>
    <div className="text-center font-extralight border rounded px-5 py-5 lg:mx-[10rem] mb-10">
    <p className="lg:text-xl">
    &nbsp;&nbsp;&nbsp;&nbsp;Hello, I'm <span className="font-bold">Mohammed Rabil</span>, a <span className="font-bold">software developer</span>.<br /><br />
    I have worked on several projects and am currently engaged in a few more.<br /><br />
    My fascination with computers and programming motivates me to continually expand my knowledge.<br /><br />
    I thrive on challenges and am always seeking new ways to enhance my skills.
    I may not know everything, but I am constantly learning.<br /><br />
</p>

    </div>
        {/* <span className="flex items-center text-gray-400 text-center font-extralight float-right">Site powered by, &nbsp;<SiReact />&nbsp;React and &nbsp;<SiTailwindcss />&nbsp;Tailwindcss. </span> */}
    </MotionAnimate>
}

export default About;