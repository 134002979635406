import SocialIcons from "../components/SocialIcons";
import MotionAnimate from "../components/MotionAnimate";
const Index = () => {
    return  <MotionAnimate><div className="flex-col items-center text-center justify-center space-y-10">
        <span className="italic font-extralight">Hey there!. Nice to meet you.</span>
        <h1 className="text-3xl lg:text-6xl font-extrabold">I'm Mohammed Rabil<span className="flex items-center justify-center text-lg lg:text-xl font-extralight">Software Developer</span></h1>
        <p className="mx-2 lg:mx-20 mt-5 font-medium text-lg">
            As a <span className="text-red-500">developer</span> deeply <span className="text-yellow-500">enthusiastic</span> about designing <span className="text-green-500">swift,</span> <span className="text-blue-500">efficient,</span> and <span className="text-indigo-500">scalable systems</span>.<br /> I relish the opportunity to <span className="text-purple-500">embrace new challenges</span>.<br /> My <span className="text-emerald-500">insatiable appetite</span> for learning drives me to continually seek innovative approaches for <span className="text-pink-500">self-improvement</span>.<br /> My <span className="text-orange-500">ultimate aspiration</span> is to advance my skills as a developer and <span className="text-cyan-500">contribute to a better world for all</span>.<br /> You can connect with me through the links below.
        </p>

        {/* <p className="font-medium font-mono">I like to build simple, fast, scalable and intuitive.</p> */}
        <SocialIcons />
        
    </div></MotionAnimate>
};

export default Index;
