import {Routes, Route, useLocation} from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import './App.css';
import Navbar from './components/Navbar';
import About from './pages/about';
import Contact from './pages/contact';
import Index from './pages/index';
import Projects from './pages/projects';
import NotFound from './pages/NotFound';
import MartialArts from './pages/projects/martial-arts';

function App() {
  const location = useLocation(); 
  const currentYear = new Date().getFullYear();
  document.title = "RABFOLIO";
  return (<>
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
  <Navbar />
  <div className="container mx-auto px-4 my-auto py-2">
  <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<Index/>} />
        <Route exact path="/projects" element={<Projects />} />
        <Route exact path="/projects/martial-arts" element={<MartialArts />} />
        <Route exact path="/contact" element={<Contact/>} />
        <Route exact path="/about" element={<About/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
      <footer className='mt-6'>
        <small className='text-gray-400 opacity-50'>&copy; {currentYear} Mohammed Rabil</small>
      </footer>
    </div>
    </div>
    </>
  );
}

export default App;
