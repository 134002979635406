import { motion } from "framer-motion";

const MotionAnimate = ({ children, ...props }) => {
    return (
        <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }} 
        {...props}>
            {children}
        </motion.div>
    );
}

export default MotionAnimate;