// import { SiReact, SiTailwindcss } from "react-icons/si";
import MotionAnimate from "../components/MotionAnimate";

const NotFound = () => {
    return <MotionAnimate>
        <h1 className="text-4xl text-center mb-5 lg:text-6xl font-extrabold">404!</h1>
    <div className="text-center font-extralight px-2 py-5 lg:mx-[10rem] mb-10">
        <p className="lg:text-xl">
            The Page you are looking for is not found.
        </p>
    </div>
        {/* <span className="flex items-center text-gray-400 text-center font-extralight float-right mt-[20rem]">Site powered by, &nbsp;<SiReact />&nbsp;React and &nbsp;<SiTailwindcss />&nbsp;Tailwindcss. </span> */}
    </MotionAnimate>
}

export default NotFound;