import logo from '../logo.svg'

const Loader = () => {
    return (
        <div id='loader' className="backdrop-blur bg-gray-800/40 flex flex-col items-center place-content-center fixed inset-0 overflow-y-auto drop-shadow-2xl">
            <h1 className="flex flex-col items-center text-4xl text-sky-300 drop-shadow-xl font-extrabold
            ">
                <img src={logo} className="max-h-20 animate-spin drop-shadow-2xl" alt='logo animation'/>Loading...
            </h1>
            <p className="
                font-medium text-gray-100 drop-shadow-2xl
            ">
                Please wait a moment.
            </p>
        </div>
    )
}

export default Loader;