import { FaGithub, FaLinkedinIn, FaMailBulk, FaInstagram, FaTelegram } from "react-icons/fa";

const SocialIcons = () => {
    const iconClass = "cursor-pointer text-4xl lg:text-6xl m-2 hover:text-stone-400 mr-2 mb-2 drop-shadow-xl shadow-white animate-bounce delay-100 hover:shadow-stone-800 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300";
    return <>
    <div className="flex justify-center pt-5 drop-shadow">
            <FaGithub className={iconClass} onClick={() => {window.open("https://github.com/rabilrbl", "_blank")}} />
            <FaMailBulk className={iconClass} onClick={() => {window.location.href ="mailto:mail@rabil.me"}} />
            <FaLinkedinIn className={iconClass} onClick={() => {window.open("https://linkedin.com/in/rabilrbl", "_blank")}} />
            <FaInstagram className={iconClass} onClick={() => {window.open("https://instagram.com/rabilrbl", "_blank")}} />
            <FaTelegram className={iconClass} onClick={() => {window.open("https://telegram.me/rabilrbl", "_blank")}} />
        </div>
    </>
};

export default SocialIcons;
