import MotionAnimate from "../components/MotionAnimate"

import projectsList from "../projects.json"


const Projects = () => {
    return <MotionAnimate><div className="">
        <h1 className="text-4xl xl:text-6xl font-extrabold m-1 text-center italic">Projects</h1>
        <div className="flex mt-5 justify-around text-center">
            <ul>
                {projectsList.map((item, index) => {
                    return <li key={index.toString()} onClick={() => {window.open(item.link, "_blank")}} 
                    className="cursor-pointer border rounded-2xl sm:rounded-full px-5 py-10 max-w-3xl
                                    my-5 hover:bg-gray-800 hover:text-gray-200 transition ease-in-out hover:-translate-y-1 hover:-translate-x-1 hover:scale-110 duration-200 hover:animate-pulse">
                                <h2 className="text-2xl font-bold ">{item.title}</h2>
                                <article className="m-2 text-xl font-extralight ">{item.description}</article>
                        </li>
                })}
            </ul>
        </div>
    </div></MotionAnimate>
}

export default Projects;
