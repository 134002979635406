import { NavLink } from 'react-router-dom';
import { GoHome } from 'react-icons/go';
import {BsCodeSlash} from 'react-icons/bs';
import {MdConnectWithoutContact} from 'react-icons/md';
import {SiAboutdotme} from 'react-icons/si';

const Pages = [
    {
        name: 'Home',
        item: <GoHome className="text-2xl" />,
        path: '/'
    },
    {
        name: 'Projects',
        item: <BsCodeSlash className="text-2xl" />,
        path: '/projects'
    },
    {
        name: 'Contact',
        item: <MdConnectWithoutContact className="text-2xl" />,
        path: '/contact'
    },
    {
        name: 'About',
        item: <SiAboutdotme className="text-2xl" />,
        path: '/about'
    },
];

const Navbar = () => {
    return (
            <div className='flex '>
        <nav className="w-full flex justify-around min-w-sm sm:gap-10 sm:justify-center bg-stone-800 py-2 ">
                {
                    Pages.map((page, index) => {
                        return <NavLink title={page.name} key={index} className={`px-2 py-1 font-thin font-sans focus:delay-100 hover:text-stone-400 transition ease-in-out hover:-translate-y-1 hover:scale-110 hover:animate-pulse rounded-full hover:shadow-gray-300 duration-200 right-0 overflow-hidden`} to={page.path} >
                            { page.item }
                        </NavLink>
                    })
                }
        </nav>
            </div>
    );
};

export default Navbar;